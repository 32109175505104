.iosch-header{
  width: 100%;
  text-align: center;
  font-size: large;
  font-weight: 600;
  display: flex; 
  justify-content: center;  
  align-items: center;
  background: #e3e3e3;
  min-height: 2.5rem;
}
.iosch-item {
  /* display: flex; */
  border-top: solid 1px #eee;
}
.iosch-item .iosch-title {
  width: calc(100% - 1rem ) ;
  padding: .3rem .5rem .3rem;
  font-size: 15px;
}

.iosch-item .iosch-text {
  width: calc(100% - 2rem ) ;
  padding: .3rem 1rem;
  min-height: 2rem;
  display: flex;  
  font-size: 17px;
  font-weight: 400;
  /* justify-content: center;   */
  align-items: center;
}