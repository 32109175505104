.bookin {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: url('./bg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.bookin > .bihead{
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bookin > .biform{
  height: calc(100vh - 50px);
  overflow: auto;
}